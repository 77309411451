<!-- @format -->

<template>
    <div class="SignIn">
        <div class="BG#FFF signup-wrapper" v-if="!signUpSuccess">
            <img src="@/assets/images/logo.svg" alt="" class="logo" />

            <v-container class="message-container">

                <img class="mt-3" src="@/assets/images/circle-check.svg" alt="" />

                <h3 class="message-title">Credential Under Review</h3>

                <p class="message-description">We’ve received your request for sign in credential. Our team will review and
                    inform you once approved.</p>

                <div class="requestUser-alert-container">
                    <div class="div-requestUser-alert-icon">
                        <img src="@/assets/icons/alert-info-gray.svg" alt="alert">
                    </div>
                    <p class="requestUser-alert-text">
                        For any concern, reach out to our team at <span style="color:#1A6D9E"><a href="mailto:hello@shifl.com" class="custom-email-link">hello@shifl.com</a>
</span>
                    </p>
                </div>
            </v-container>
            <div class="already-account">
                <span class="ask-account-text"> <a style="color:  #0171A1;

font-weight: 600;" href="https://shifl.com/">Learn about Shifl</a>
                </span>
            </div>
        </div>


    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    components: {
    },
    data: () => ({

    }),
    computed: {
        ...mapGetters(["getErrorMessage"]),
    },
    methods: {
        ...mapActions(["signUp"]),

    },
    mounted() {

    },
    updated() { },
};
</script>

<style>
.signup-wrapper .error-message {
    display: flex;
    justify-content: left;
    align-items: flex-start;
}

.signup-wrapper .error-message img {
    margin-top: 3px;
}

.signup-wrapper .error-message .error-text {
    font-size: 10px;
    text-align: left;
    color: red;
    padding-left: 10px;
    margin-bottom: 10px;
}


.message-container {
    background-color: white;
    width: 380px;
}

.message-title {
    margin-top: 16px;
}

.message-description {
    margin-top: 8px;
    font-size: 14px;
    margin-inline: 12px;
}

.already-account {
    margin-top: 32px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--neutral-n-90, #253041);

}


.requestUser-alert-container {
    display: flex;
    align-items: center;
    color: var(--neutral-n-90, #253041);
    border: 1px solid #F3F4F7;
    border-radius: 5px;
}

.div-requestUser-alert-icon {
    margin-right: 10px;
    height: 56px;
    width: auto;
    background-color: #F3F4F7;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-inline: 8px;



}

.requestUser-alert-icon {
    height: 20px;
    width: 20px;
}

.requestUser-alert-text {
    font-size: 14px;
    text-align: start;
    align-items: center;
    margin: 0px;
    margin-bottom: 0px !important;
    /* Adjust text size as needed */
}

.custom-email-link {
  color: #1A6D9E;
  text-decoration: none; 
  font-weight: 400;
}

</style>
